import * as React from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "../../../Components/Button/Button";
import { useAppSelector } from "../../../store/hooks";
import UPI from "../../../Assets/UPI.jpg";

const UPIModal = ({ open, onClose }) => {
  const handleClose = () => onClose();
  const darkMode = useAppSelector((store) => store.theme.dark);
  const darkTheme = createTheme({ palette: { mode: "dark" } });
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const themeProv = React.useMemo(
    () => (darkMode ? darkTheme : lightTheme),
    [darkMode, darkTheme, lightTheme],
  );
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={themeProv}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="terms-and-conditions-dialog"
        aria-describedby="terms-and-conditions-dialog-description"
      >
        <DialogTitle>UPI</DialogTitle>
        <DialogContent>
          <Box display={"flex"} justifyContent={"center"}>
            <img src={UPI} width={isMdDown ? 300 : 380} alt="UPI QR code" />
          </Box>

          <Typography mt={4} id="steps-to-follow">
            Once you make the payment, please send a screenshot of the payment
            confirmation along with your email address and mobile number via
            WhatsApp to{" "}
            <span style={{ textDecoration: "underline" }}>+91-7874999980</span>{" "}
            and email it to{" "}
            <span style={{ textDecoration: "underline" }}>
              support@darkhorsestocks.in
            </span>{" "}
            . Your account will be activated within a maximum of 24 hours once
            we receive your confirmation.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 6, py: 4 }}>
          <Button text="Close" onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default UPIModal;
